module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-18406550-2","anonymize":true,"respectDNT":true,"exclude":["/preview/**"],"cookieDomain":"intactile.com","head":false,"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"intactile DESIGN","short_name":"intactile","start_url":"/","background_color":"#ce474e","theme_color":"#ce474e","display":"minimal-ui","icon":"src/images/icon-intactile.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d06e1c25187c159cda846a7568be6ba2"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
